import React, { useState, createContext } from 'react';

const StoreContext = createContext(null);

export const AppContextProvider = ({ children }) => {
  const [queryParam, setQueryParam] = useState(null);
  
  const context ={ 
    queryParam, 
    setQueryParam 
  };

  return (
    <StoreContext.Provider value={context}>
         {children}
    </StoreContext.Provider>
  );
};

export default StoreContext;
